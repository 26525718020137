'use strict';
angular.module('aides.services').factory('aidesService', aidesService);

aidesService.$inject = [
  '$http',
  '$q',
  '$rootScope',
  '$location',
  'jsonpatch',
  'Aide',
  'Tiers',
  'configuration',
  'urlService',
  'cmisService',
  'tiersService',
  'teleservicesService',
  'groupesGestionService',
  'bourseService',
  '$sce',
  'jwtSessionService',
  '$log',
  'AidesConstant',
  'contributionsConstants',
  'Piece',
  'demandesFinancementAPI',
  'dossiersFinancementAPI',
  '$httpParamSerializer',
];

/**
 *
 * @param {object} $http
 * @param {object} $q
 * @param {object} $rootScope
 * @param {object} $location
 * @param {object} jsonpatch
 * @param {object} Aide
 * @param {object} Tiers
 * @param {object} configuration
 * @param {object} urlService
 * @param {object} cmisService
 * @param {object} tiersService
 * @param {object} teleservicesService
 * @param {object} groupesGestionService
 * @param {object} bourseService
 * @param {object} $sce
 * @param {object} jwtSessionService
 * @param {object} $log
 * @param {object} AidesConstant
 * @param {object} contributionsConstants
 * @param {object} Piece
 * @param {object} demandesFinancementAPI
 * @param {object} dossiersFinancementAPI
 * @param {Function} $httpParamSerializer
 * @returns {object}
 */
function aidesService(
  $http,
  $q,
  $rootScope,
  $location,
  jsonpatch,
  Aide,
  Tiers,
  configuration,
  urlService,
  cmisService,
  tiersService,
  teleservicesService,
  groupesGestionService,
  bourseService,
  $sce,
  jwtSessionService,
  $log,
  AidesConstant,
  contributionsConstants,
  Piece,
  demandesFinancementAPI,
  dossiersFinancementAPI,
  $httpParamSerializer
) {
  ('use strict');
  const AIDE_STATUS = AidesConstant.demandeStatuts;
  const statusContributions = contributionsConstants.status;

  let savingAide = false;

  /**
   * get ressource path from configuration
   *
   * @returns {string} ressource path
   */
  function ressourcePath() {
    return configuration.aides?.ressourcePath || 'aides';
  }

  /**
   * get gestion depot url from configuration
   *
   * @returns {string} gestion depot url
   */
  function gestionDepotDemandesUrl() {
    return configuration.aides?.url || '/gestion-depot-demandes';
  }

  /**
   * TODO this function is temporary
   * It allows to request tiers separately in order to obtain the correct version from referentiel-tiers
   *
   * @param {object} aide aide containing tiers (demandeur or beneficiaire)
   * @param {string} path path to tiers in aide
   * @param {string[]} expands array of expands to add to the tiers
   * @param {Promise[]} requests array of promises to feed
   * @param {object} mdm
   */
  function expandTiers(aide, path, expands, requests, mdm) {
    // If tiers is registered in referentiel-tiers, simply request it with expands
    if (_.get(aide, path + '.href')) {
      requests.push(
        $http
          .get(_.get(aide, path + '.href'), {
            params: {
              expand: expands.join(','),
            },
          })
          .then(function (response) {
            // Write it in aide
            const tiers = new Tiers(response.data, mdm);
            _.set(aide, path + '.expand', tiers);
            return tiers;
          })
      );
    }
    // Otherwise (if it's directly in demande), we have to call expands one by one
    else {
      _.each(expands, function (tiersProperty) {
        const dataHref = _.get(aide, path + '.expand.' + tiersProperty + '.href');
        if (dataHref) {
          requests.push(
            $http.get(dataHref).then(function (response) {
              // Write in aide
              _.set(aide, path + '.expand.' + tiersProperty + '.expand', response.data);
              return response.data;
            })
          );
        }
      });
    }
  }

  /**
   * Fonction de récupération des expands liés aux documents de l'aide
   *
   * @param {object} newAide
   * @param {object} oldAide
   */
  function gererExpandDocuments(newAide, oldAide) {
    if (!_.isEmpty(newAide.pieces) && !_.isEmpty(oldAide.pieces)) {
      _.map(oldAide.pieces, function (oldPiece) {
        const piecePersistance = _.find(newAide.pieces, function (newPiece) {
          return newPiece.reference === oldPiece.reference;
        });
        if (piecePersistance && !_.isEmpty(piecePersistance.documents) && !_.isEmpty(oldPiece.documents)) {
          _.map(oldPiece.documents, function (oldDocument) {
            const documentPersistence = _.find(piecePersistance.documents, function (newDocument) {
              return newDocument.id === oldDocument.id;
            });
            if (documentPersistence) {
              // Dans le cas ou on a perdu les properties (expand) du document lors de la sauvegarde, on les rajoute dans le nouveau document
              const newPropertiesDocument = _.get(documentPersistence, 'expand.properties');
              const oldPropertiesDocument = _.get(oldDocument, 'expand.properties');
              if (
                oldPropertiesDocument &&
                !_.isEmpty(oldPropertiesDocument) &&
                (!newPropertiesDocument || _.isEmpty(newPropertiesDocument))
              ) {
                _.set(documentPersistence, 'expand.properties', oldPropertiesDocument);
              }
            }
          });
        }
      });
    }
  }

  /**
   * Get expands from pieces documents to put them on planFinancement documents
   *
   * @param {object} newAide
   * @param {object} oldAide
   */
  function gererExpandPlanFinancementDocuments(newAide, oldAide) {
    // retrieve old documents and make it unique
    const oldDocuments = new Set(JSONPath('$.pieces[*].documents[?(@.expand)]', oldAide));

    // planFinancement documents
    const newPlanFinancementDocuments = JSONPath('$..depense..documents[*]', newAide.planFinancement || []);

    // use .size because it's a Set
    if (oldDocuments.size && newPlanFinancementDocuments.length) {
      oldDocuments.forEach((oldDocument) => {
        // get all documents with the same id
        const newDocuments = newPlanFinancementDocuments.filter((doc) => doc.id === oldDocument.id);

        newDocuments.forEach((document) => {
          // In the case we lost expand on document when saving aide, we add them in the new document
          const newPropertiesDocument = _.get(document, 'expand.properties');
          const oldPropertiesDocument = _.get(oldDocument, 'expand.properties');
          if (
            oldPropertiesDocument &&
            !_.isEmpty(oldPropertiesDocument) &&
            (!newPropertiesDocument || _.isEmpty(newPropertiesDocument))
          ) {
            _.set(document, 'expand.properties', oldPropertiesDocument);
          }
        });
      });
    }
  }

  /**
   * Evalue la condition complémentaire paramétrée sur un téléservice pour l'avis préalable d'une aide
   *
   * @param {object} conditionComplementaire Condition complémentaire paramétrée sur le téléservice
   * @param {object} aide Aide déposée
   * @returns {object} TRUE si la condition est absente ou remplie, FALSE sinon
   */
  function evaluerConditionComplementaireAvisPrealable(conditionComplementaire, aide) {
    // Pas de condition complémentaire : fin
    if (_.isEmpty(conditionComplementaire)) {
      return true;
    }

    const scope = $rootScope.$new();
    // Allow moment.js to be available in scope, so isPieceVisible or isPieceRequired function can evaluate conditions based on date
    scope.moment = moment;
    scope._ = _;

    // Evaluation de la condition complémentaire
    return scope.$eval(conditionComplementaire, aide);
  }

  /**
   *
   * @param {object} params
   * @param {object[]} tiersRequests
   */
  function manageExpandTiers(params, tiersRequests) {
    const expand = params.expand;

    // Regex used to determine if an expand is on a tiers
    const tiersRegex = tiersRequests.reduce(function (regex, config, index) {
      regex += '(' + config.key + ')' + (index < tiersRequests.length - 1 ? '|' : '');
      return regex;
    }, '');
    if (new RegExp(tiersRegex).test(expand)) {
      // split expand for each requests
      const expandAide = [];
      _.each(expand.split(','), function (expandPart) {
        _.each(tiersRequests, function (reqConfig) {
          // If the expand is on a tiers, it belongs to its request
          if (new RegExp('^' + reqConfig.key + '(\\.expand)?\\.').test(expandPart)) {
            reqConfig.expand.push(expandPart.replace(new RegExp('^' + reqConfig.key + '(\\.expand)?\\.'), ''));
          }
        });
        // Otherwise, it's on the aide request
        if (!new RegExp(tiersRegex).test(expandPart)) {
          expandAide.push(expandPart);
        }
      });

      params.expand = expandAide.join(',');
    }
  }

  /**
   *
   * @param {string} uri
   * @param {object} mdm
   * @param {object} params
   * @returns {Promise}
   */
  function get(uri, mdm, params) {
    const config = {
      params: params,
    };

    // TODO This is a temporary solution
    // Since referentiel-financement use tiers 2.19, we extract the expands to call directly referentiel-tiers
    const tiersRequests = _.map(['demandeur', 'beneficiaires', 'mandataires'], function (tiersKey) {
      // Requests for demandeur, beneficiaires, mandataires
      return {
        key: tiersKey,
        active: new RegExp(tiersKey).test(params.expand),
        expand: [],
      };
    });

    manageExpandTiers(config.params, tiersRequests);

    // Request aide
    return $http.get(uri, config).then((response) => {
      const aide = new Aide(response.data, mdm);

      // TODO temporary
      // Request tiers from referentiel-tiers
      const requests = [];
      _.each(tiersRequests, function (requestConfig) {
        if (requestConfig.active) {
          // Demandeur need only one request, others are multiple
          if (!_.isArray(aide[requestConfig.key])) {
            expandTiers(aide, requestConfig.key, requestConfig.expand, requests, mdm);
          } else {
            _.each(aide[requestConfig.key], function (beneficiaire, index) {
              expandTiers(aide, requestConfig.key + '.' + index, requestConfig.expand, requests, mdm);
            });
          }
        }
      });

      return $q
        .all(requests)
        .then(function () {
          return aide;
        })
        .catch((error) => {
          $log.error('[aidesService] get - an error occured while fetching tiers linked to a demande', error);
          throw error;
        });
    });
  }

  /**
   * generate aide href
   *
   * @param {string} reference
   * @returns {string} href
   */
  function getAideHref(reference) {
    return `${gestionDepotDemandesUrl()}/${ressourcePath()}/${reference}`;
  }

  /**
   * retreive the aide by reference
   *
   * @param {string} reference
   * @returns {Promise}
   */
  function getAide(reference) {
    return $http.get(getAideHref(reference)).then((res) => res.data);
  }

  /**
   * Manage action after aide saved, used by aide and contribution service
   * ! this method mutates the aide parameter
   *
   * @param {object} aide the current aide
   * @param {object} aideUpdated the updated aide
   * @param {boolean} aideContext true if it's used by aideService
   * @returns {void}
   */
  function postUpdateAide(aide, aideUpdated, aideContext) {
    if (aideContext) {
      // Update aide with only added properties (like reference and links)
      const allPatches = jsonpatch.compare(aide, aideUpdated);
      const patchesToApply = allPatches.filter((patch) => {
        const isPlanFinancement = patch.path.startsWith('/planFinancement');
        const propertyActualValue = jsonpatch.getValueByPointer(aide, patch.path);

        const shouldUpdate =
          patch.op !== 'remove' &&
          ![undefined, null].includes(patch.value) &&
          propertyActualValue === undefined &&
          !isPlanFinancement;

        return shouldUpdate;
      });

      jsonpatch.applyPatch(aide, patchesToApply);

      // Display the menu echange on footer after the demande is stored
      $rootScope.displayEchangeMenu = { value: $rootScope.echangesActif, demandeId: aideUpdated.reference };
    }

    // Multifinanceur data are calculated in the API side, so we update the information
    if (_.has(aideUpdated, 'multiFinanceur')) {
      aide.multiFinanceur = _.get(aideUpdated, 'multiFinanceur');
    }
    if (_.has(aideUpdated, 'optionsMultiFinanceur')) {
      aide.optionsMultiFinanceur = _.get(aideUpdated, 'optionsMultiFinanceur');
    }

    if (!_.isEmpty(_.get(aideUpdated, 'planFinancement'))) {
      aide.planFinancement = _.get(aideUpdated, 'planFinancement');
    }

    if (aideUpdated._metadata) {
      aide._metadata = aideUpdated._metadata;
    }
  }

  /**
   * export expand documents from oldAide to newAide
   *
   * @param {object} newAide
   * @param {object} oldAide
   */
  function gererExpandDocumentsAide(newAide, oldAide) {
    // Get expand properties of aide's documents
    gererExpandDocuments(newAide, oldAide);

    // retrieve expands for documents in planFinancement
    gererExpandPlanFinancementDocuments(newAide, oldAide);
  }

  /**
   * test if ligne have at least ONE non CLOTURE DECISION in its pf events
   *
   * @param {object} ligneFictive
   * @returns {boolean} isLigneFictiveHaveActiveDecisions
   */
  function isLigneFictiveHaveActiveDecisions(ligneFictive) {
    if (!ligneFictive) throw new Error('[isLigneFictiveHaveActiveDecisions] - Missing aide param');

    return dossiersFinancementAPI.getActiveDecisions(ligneFictive).length > 0;
  }

  /**
   * Returns if an aide is owned by current user
   *
   * @param {object} aide
   * @returns {boolean}
   */
  function isOwnedByCurrentUser(aide) {
    return _.get($rootScope, 'currentUser.self') === _.get(aide, 'history.begin.user.href');
  }

  /**
   * Returns if the aide status is REQUESTED
   *
   * @param {object} aide
   * @returns {boolean}
   */
  function isRequested(aide) {
    return aide.status === AIDE_STATUS.REQUESTED;
  }

  /**
   * Returns if the given tiers is the aide demandeur
   *
   * @param {object} aide
   * @param {object} tiers
   * @returns {boolean}
   */
  function isTiersDemandeur(aide, tiers) {
    return aide?.demandeur?.href === tiers?.id;
  }

  /**
   * Returns if the given tiers is the aide beneficiaire
   *
   * @param {object} aide
   * @param {object} tiers
   * @returns {boolean}
   */
  function isTiersBeneficiaire(aide, tiers) {
    return aide?.beneficiaires?.[0]?.href === tiers?.id;
  }

  return {
    /**
     * Retrieve decisions history for a specific demande
     * If dossierReference is specified, then filter decisions for this dossier
     *
     * @param {string} reference Aide reference
     * @param {string} [dossierReference] Dossier reference
     * @returns {Promise<Array>}
     */
    getDecisionsHistory: function (reference, dossierReference) {
      const url =
        '/aides/api/tenants/' +
        _.get(configuration, 'tenant.id') +
        '/' +
        ressourcePath() +
        '/' +
        reference +
        '/decision-history';

      return $http({ method: 'GET', url: url }).then(function (resp) {
        const decisions = resp.data || [];
        return !dossierReference
          ? decisions
          : decisions.filter(
              ({ dossierFinancement }) => _.get(dossierFinancement, 'href', '').split('/').pop() === dossierReference
            );
      });
    },

    /**
     * Make the automatic prise-en-charge if conditions are fullfilled
     * this means,
     * tiers.status = SUPPORTED
     * agent administrator asigned to linkedUsers as OWNER
     *
     * @param {object} demandeur tiers demandeur
     * @param {object} teleservice teleservice of the aide (used to check if the tiers should be created with SUPPORTED status)
     * @param {string} tenantId id of the current tenant
     * @returns {Promise}
     */
    priseEnChargeAuto: function (demandeur, teleservice, tenantId) {
      // If the teleservice is well configured and the tiers matches all conditions,
      // Then the new tiers is sent in 'SUPPORTED' status

      // we need the last version of teleservice to fullfill some conditions
      return teleservicesService.getTeleService(teleservice.reference).then((lastTeleservice) => {
        return tiersService
          .canCreateSupportedTiers(demandeur, teleservice, lastTeleservice)
          .then((canCreateSupportedTiers) => {
            if (canCreateSupportedTiers) {
              // we remove previous owner (the actual user)
              const linkedUsersArray = demandeur.linkedUsers
                .filter((linkedUser) => linkedUser.form !== 'OWNER')
                .map((item) => {
                  // we clean invalid properties (not in contract)
                  delete item.expand;
                  return item;
                });
              const adminLinkedUsers = {
                href: `/account-management/${tenantId}-agents/users/administrator`,
                title: 'Traitement AUTOMATIQUE',
                form: 'OWNER',
              };

              // and we add the new OWNER (agent 'administrator')
              // this user is used as agent for prise-en-charge-auto feature
              linkedUsersArray.push(adminLinkedUsers);

              const patches = [
                { op: 'replace', path: '/status', value: 'SUPPORTED' },
                { op: 'replace', path: '/linkedUsers', value: linkedUsersArray },
              ];

              return tiersService.patchTiers(demandeur.reference, patches);
            } else {
              return demandeur;
            }
          });
      });
    },

    /**
     * Check if an avis préalable is necessary for the aide
     * this method mutates the aide parameter
     *
     * @param {object} aide
     * @param {object} contribution
     * @returns {object}
     */
    determinerAvisPrealable: (aide, contribution) => {
      return $q((resolve) => {
        // we don't have to check for avisPrealable if contribution isn't a redirection
        if (contribution && contribution.typeContribution !== 'REDIRECTION') return resolve(aide);

        // Default status : TRANSMITTED (no avisPrealable)
        if (!contribution && aide.status !== 'WAITING_FOR_CERTIFICATE') {
          aide.status = 'TRANSMITTED';
        }

        // Get avisPrealables configuration of the teleservice
        const typeWorkflow = contribution
          ? _.get(contribution, 'teleservice.workflow.type')
          : _.get(aide, 'teleservice.expand.workflow.type');
        const avisPrealableTeleservice = contribution
          ? _.get(contribution, `teleservice.workflow.${typeWorkflow}.avisPrealable`)
          : //! teleservice isn't always using typeWorkflow in his path
            _.get(aide, `teleservice.expand.workflow.${typeWorkflow}.avisPrealable`) ||
            _.get(aide, `teleservice.expand.workflow.avisPrealable`);

        // "Avis préalable" isn't enabled on the teleservice
        if (!avisPrealableTeleservice || !avisPrealableTeleservice.actif) return resolve(aide);

        // Get the "Tiers connu" on the teleservice if there is one
        const tiersConnu = avisPrealableTeleservice.tiers;
        const tiersConnuHref = tiersConnu && tiersConnu.href;

        // The "Tiers pour avis préalable" on the teleservice is a "Tiers connu"
        if (tiersConnuHref) {
          // The "Tiers connu" isn't the demandeur
          if (tiersConnuHref !== aide.demandeur.href) {
            // The additional condition of the teleservice isn't filled
            if (
              evaluerConditionComplementaireAvisPrealable(
                _.get(avisPrealableTeleservice, 'conditionComplementaire'),
                aide
              )
            ) {
              // The "Tiers connu" is designated as a "Contributeur pour avis préalable" on the aide
              aide.avisPrealables = [
                {
                  tiers: {
                    title: tiersConnu.title,
                    href: tiersConnuHref,
                    mail: tiersConnu.mail,
                  },
                },
              ];

              aide.status = 'REGISTERED';
            }
          }

          return resolve(aide);
        }

        // The "Tiers pour avis préalable" is the "chef de file" on the aide contrat
        const contratHref = _.get(aide, 'contrat.href');

        // No contrat on the aide
        if (!contratHref) return resolve(aide);

        $http.get(contratHref).then((response) => {
          const contrat = response.data;

          // The aide has a contrat
          if (contrat) {
            const chefDeFile = contrat.chefDeFile;
            const chefDeFileHref = chefDeFile && chefDeFile.href;

            // The contrat has a "chef de file"
            if (chefDeFileHref) {
              // The "chef de file" isn't the demandeur
              if (chefDeFileHref !== aide.demandeur.href) {
                // The additional condition of the teleservice isn't filled
                if (
                  evaluerConditionComplementaireAvisPrealable(
                    _.get(avisPrealableTeleservice, 'conditionComplementaire'),
                    aide
                  )
                ) {
                  // The "chef de file" is designated as a "Contributeur pour avis préalable" on the aide
                  aide.avisPrealables = [
                    {
                      tiers: {
                        title: chefDeFile.title,
                        href: chefDeFileHref,
                        mail: chefDeFile.mail,
                      },
                    },
                  ];

                  aide.status = 'REGISTERED';
                }
              }
            }
          }

          return resolve(aide);
        });
      });
    },

    /**
     * Valid an aide
     *
     * @param {object} demandeur demandeur of the aide
     * @param {object} newAide aide to create
     * @param {object} teleservice teleservice of the demande
     * @param {string} tenantId tenant id
     * @returns {Promise}
     */
    validAide: function (demandeur, newAide, teleservice, tenantId) {
      newAide = urlService.deleteBaseUrlInEntity(configuration.baseUrl, newAide);

      const aide = new Aide(newAide);

      if (aide.status !== 'WAITING_FOR_CERTIFICATE') {
        aide.status = 'TRANSMITTED';
      }

      if (this.isModificationAttestation(aide)) {
        const userAdministrateurDemande = _.find(aide.linkedUsers, { form: 'ADMINISTRATOR' });
        userAdministrateurDemande.href = _.get(aide, 'user.href');
        userAdministrateurDemande.title = _.get(aide, 'user.title');
      }

      // remove metadatas used only for the depot process
      _.unset(aide, 'history.begin.metadata.searchTypeDemandeur');
      _.unset(aide, 'history.begin.metadata.searchTypeBeneficiaire');
      _.unset(aide, 'history.begin.metadata.representantLegal');
      _.unset(aide, 'history.begin.metadata.representantLegalBeneficiaire');

      return (
        tiersService
          .getCurrentTiers({}, 'famille')
          // Returns the tiers of the user if exists and create demandeur tiers as the tiers of the user otherwise
          .then((linkedTiers) => {
            return linkedTiers || this.createDemandeur(demandeur, aide);
          })
          .then((demandeur) => {
            // if needed, we make the prise-en-charge-auto
            return this.priseEnChargeAuto(demandeur, teleservice, tenantId);
          })
          .then((demandeur) => {
            aide.demandeur = {
              rel: 'tiers',
              title: tiersService.getTitleTiers(demandeur),
              href: demandeur.id,
            };

            return this.createBeneficiaires(aide, demandeur);
          })
          .then(([beneficiaire]) => {
            // If a tiers has been imported, pieces doesn't exist, so we have to retrieve them from the famille
            const config = {
              params: {
                expand: 'famille',
              },
            };
            // to be sure of our patch generation, we get the last version of the tiers
            return $http.get(beneficiaire.href, config).then(({ data }) => data);
          })
          .then((beneficiaire) => {
            const observer = jsonpatch.observe(beneficiaire);

            this.createMissingPiecesOnTiers(aide, beneficiaire);

            return this.copyAideDocumentsOnTiers(beneficiaire, aide).then(() => {
              const patches = jsonpatch.generate(observer);
              if (!_.isEmpty(patches)) {
                return tiersService.patchTiers(beneficiaire.reference, patches);
              }
            });
          })
          .then(() => this.determinerAvisPrealable(aide))
          .then(() => {
            if (aide.status === 'TRANSMITTED') {
              return this.updateDemandeurGroupesGestion(aide);
            } else {
              return aide;
            }
          })
          .then((aide) => this.saveAide(aide))
          .then((savedAide) => {
            aide.reference = savedAide.reference;
            aide._links = savedAide._links;
            aide.title = savedAide.title;
            return aide;
          })
      );
    },

    /**
     * Add or update an aide
     *
     * @param {object} aide Aide
     * @returns {object} Aide
     */
    saveAide: function (aide) {
      // we lock concurrent calls
      if (savingAide) return $q.reject('[saveAide] - there is another call in progress');

      savingAide = true;
      let promise;

      if (!_.get(aide, 'contrat.href')) {
        _.unset(aide, 'contrat');
      }
      const isUpdate = aide.reference !== undefined;
      if (isUpdate) {
        // is an update, we need to make a PUT call
        const oldAide = angular.copy(aide);
        promise = this.update(aide).then((updateAide) => {
          postUpdateAide(aide, updateAide, true);
          // Manually update _metadata
          if (_.has(updateAide, '_metadata')) {
            _.set(aide, '_metadata', updateAide._metadata);
          }
          gererExpandDocumentsAide(updateAide, oldAide);
          return updateAide;
        });
      } else {
        // is a creation, we need to make a POST call
        const aideCleaned = aide.getCleanEntity(true);
        promise = this.create(aideCleaned).then((newAide) => {
          // get the new teleservice href since we moved on a revision
          aide.teleservice.href = newAide.teleservice.href;
          postUpdateAide(aide, newAide, true);
          return newAide;
        });
      }

      return promise
        .catch((err) => {
          $log.error('[saveAide] - an error has occurred the registration has failed');
          return $q.reject(err);
        })
        .finally(() => {
          savingAide = false;
        });
    },

    /**
     * Remove an aide
     *
     * @param {object} aide
     * @returns {Promise}
     */
    remove: function (aide) {
      return $http.delete(`${gestionDepotDemandesUrl()}/${ressourcePath()}/${aide.reference}`);
    },

    list: function (mdm, expand) {
      expand = _.isEmpty(expand) ? '' : `?expand=${expand}`;
      return $http.get(`${gestionDepotDemandesUrl()}/${ressourcePath()}${expand}`).then(function (response) {
        return _.map(response.data, function (aideData) {
          return new Aide(aideData, mdm);
        });
      });
    },

    /**
     * Get an aide
     *
     * @param {string} reference
     * @param {object} mdm
     * @param {string} expand
     * @returns {Promise}
     */
    get: function (reference, mdm, expand) {
      const uri = getAideHref(reference);
      return get(uri, mdm, { expand: expand || '' });
    },

    /**
     * Get a demande financement with all required expanded fields for paiment process
     *
     * @param {string} reference Demande financement reference
     * @param {object} config Request config
     * @returns {Promise<object>} Demande financement
     */
    getForPaiements: (reference, config = {}) => {
      const uri = `/aides/api/tenants/${configuration.tenant.id}/demandes-financement/${reference}/get-for-paiements`;
      return $http.get(uri, config).then((response) => {
        const aide = new Aide(response.data);
        return aide;
      });
    },

    /**
     * Get an aide merged with contribution
     *
     * @param {string} reference
     * @param {string} referenceContribution
     * @param {object} mdm
     * @param {string} expand
     * @returns {Promise}
     */
    getAideWithContribution: function (reference, referenceContribution, mdm, expand) {
      const uri = getAideHref(reference);

      const params = {
        contribution: referenceContribution,
      };

      if (expand) {
        params.expand = expand;
      }

      return get(uri, mdm, params);
    },

    /**
     * Get the href of an aide by reference
     *
     * @param {*} reference
     */
    getAideHref: getAideHref,

    getRecap: function (reference, mdm, params) {
      params.expands = params.expands || 'pieces.documents,domiciliationBancaire.pieces.documents';

      // this method is called to display the "recapitulatif" on the aide and contribution screen
      const url = `/aides/api/tenants/${_.get(
        configuration,
        'tenant.id'
      )}/${ressourcePath()}/${reference}/recapitulatif`;

      return $http
        .get(url, {
          params: params,
        })
        .then(function (response) {
          return new Aide(response.data, mdm);
        });
    },

    /**
     * Get an aide by is reference
     */
    getAide: getAide,

    /**
     * Get aides by references
     *
     * @param {string[]} references
     * @returns {Promise}
     */
    getAidesByReference: function (references) {
      if (_.isEmpty(references)) {
        return [];
      }

      if (!Array.isArray(references)) {
        references = [references];
      }

      // reference to promise
      const getAides = _.map(references, (reference) => getAide(reference));

      return $q.all(getAides);
    },

    /**
     * Get aide with expands eventually merged with a contribution
     *
     * @param {string} href
     * @param {string} expand
     * @param {object} contribution
     * @returns {Promise}
     */
    getAideExpanded: (href, expand = '', contribution) => {
      if (!href || typeof href !== 'string') return $q.reject('getAideExpanded - the href must be a non empty string');
      const query = {
        url: href,
        method: 'GET',
        params: {},
      };

      if (contribution) query.params.contribution = contribution.reference;
      if (expand) query.params.$expand = expand;

      return $http(query).then(function (response) {
        return response.data;
      });
    },

    /**
     * Expand aide with its pieces and return only pieces
     *
     * @param {string} href
     * @param {object} contribution
     * @returns {Promise<Array<Piece>>}
     */
    getAideExpandedPieces: function (href, contribution) {
      return this.getAideExpanded(href, 'pieces.documents', contribution).then((expandedAide) =>
        expandedAide.pieces.map((piece) => new Piece(piece))
      );
    },

    create: function (aide, mdm) {
      return $http.post(`${gestionDepotDemandesUrl()}/${ressourcePath()}`, aide).then(function (response) {
        return new Aide(response.data, mdm);
      });
    },
    update: function (aide, mdm) {
      // Récupération d'un objet aide nettoyé
      const aideEntity = aide.getCleanEntity(true);
      const url = `${gestionDepotDemandesUrl()}/${ressourcePath()}/${aide.reference}`;

      const config = { params: {} };
      if (aide.status === 'WAITING_FOR_CERTIFICATE') {
        const linkMail =
          $location.absUrl().split('connecte')[0] + 'connecte/dashboard/sollicitations?activeTab=attestations';
        config.params.attestationsURL = linkMail;
      }

      return $http.put(url, aideEntity, config).then(function (response) {
        return new Aide(response.data, mdm);
      });
    },

    copyDocumentDomiciliationBancaireTiers: function (baseUrlFolder, documentPiece, kind, entity) {
      return cmisService.copyDocument(baseUrlFolder, documentPiece, kind, entity);
    },

    /**
     * Patch de la liste des pièces sur une aide
     *
     * @param {string} reference
     * @param {object[]} pieces
     * @returns {Promise}
     */
    patchPieces: function (reference, pieces) {
      const patches = [
        {
          op: 'add',
          path: '/pieces',
          value: pieces,
        },
      ];

      return $http
        .patch(`${gestionDepotDemandesUrl()}/${ressourcePath()}/${reference}`, patches)
        .then(function (response) {
          return response.data;
        });
    },
    /**
     * Copy updated documents from the aide on the tiers if it doesn't exist on it
     * ! this function doesn't work properly, his behavior should be reviewed in BLA-290
     *
     * @param {object} aide
     * @param {object} tiers
     * @param {object} piecesModifie
     * @returns {Promise} Retourne le tiers avec les documents modifiés
     */
    copyTiersDocuments: function (aide, tiers, piecesModifie) {
      // Fetch tiers if reference not exists
      const getTiersPromise = tiers.reference ? $q.resolve(tiers) : tiersService.getTiersById(tiers.href);
      return getTiersPromise.then((tiers) => {
        const copyDocumentsOnTiers = [];
        _.each(tiers.pieces, function (tiersPiece) {
          tiersPiece.documents = tiersPiece.documents || [];
          // Pièces typé structure sur l'aide d'origine
          const piecesAideTiersOrigin = _.find(aide.pieces, {
            reference: tiersPiece.reference,
          });

          // Pièces typé structure sur les pièces
          let piecesAideTiers = _.find(piecesModifie, {
            reference: tiersPiece.reference,
          });

          // On filtre la liste des documents pour ne garder que les nouveaux documents
          piecesAideTiers = _.filter(_.get(piecesAideTiers, 'documents'), function (docAide) {
            return !_.find(_.get(piecesAideTiersOrigin, 'documents'), function (docAideOrigin) {
              return docAideOrigin.id === docAide.id;
            });
          });

          // Copy all documents from the aide for this piece on the tiers
          // If we reference a document from 'porte-document' is href contains 'tiers'
          _.each(piecesAideTiers, function (newDocumentAide) {
            const docTiersExist = _.find(tiersPiece.documents, function (document) {
              const doc = _.get(document, "expand.properties['cmis:objectId'].value");
              const docAide = _.get(newDocumentAide, "expand.properties['cmis:objectId'].value");
              return doc === docAide;
            });
            if (
              _.includes(newDocumentAide.href, '/aides/') &&
              !_.includes(newDocumentAide.origin, 'tiers') &&
              !_.includes(newDocumentAide.origin, 'root?objectId') &&
              !docTiersExist
            ) {
              // Copy du document ajouté
              const documentTiers = angular.copy(newDocumentAide);
              copyDocumentsOnTiers.push(
                cmisService
                  .copyDocument(`${cmisService.getBaseUrl()}/tiers/${tiers.reference}`, documentTiers, 'tiers', tiers)
                  .then(function (newDocumentsTiers) {
                    newDocumentAide.origin = newDocumentsTiers.href;
                    tiersPiece.documents.push(newDocumentsTiers);
                  })
              );
            }
          });
        });

        return $q.all(copyDocumentsOnTiers).then(function () {
          return tiers;
        });
      });
    },

    /**
     * Get caracteristiques-sociales
     *
     * @param {object} type
     * @returns {Promise}
     */
    getCaracteristiquesSociales: function (type) {
      const config = {
        params: {
          $top: 100,
        },
      };

      // Restriction sur le type
      config.params.$filter = "actif eq true and typeCaracteristiqueSociale/href eq '" + type.href + "'";

      return $http
        .get(`${gestionDepotDemandesUrl()}/valeurs-caracteristique-sociale`, config)
        .then(function (response) {
          const result = _.get(response, 'data._embedded.items');
          const listFiltree = [];
          _.each(result, function (val) {
            const newVal = {
              href: val.id,
              title: val.libelle,
            };

            listFiltree.push(newVal);
          });
          return listFiltree;
        });
    },

    /**
     * Get contracts
     *
     * @param {string[]} typeContracts
     * @param {string} searchText
     * @returns {Promise}
     */
    getContracts: function (typeContracts, searchText) {
      if (!typeContracts || typeContracts.length === 0) return $q.resolve([]);

      const config = {
        params: {
          $top: 10000,
        },

        headers: { 'Cache-Control': 'no-cache' },
      };

      // Restriction sur typeContrat
      let filter =
        typeContracts.reduce(
          function (prev, curr, index) {
            return prev.concat(index !== 0 ? " or type/href eq '" + curr.href + "'" : '');
          },
          "statut eq 'PUBLIE' and actif eq true and (type/href eq '" + typeContracts[0].href + "'",
          1
        ) + ')';

      if (!_.isNil(searchText) && searchText !== ' ') {
        filter += " and substringof(title,'" + searchText + "')";
      }

      config.params.$filter = filter;
      config.params.$orderby = 'title';
      config.params.$top = 20;

      return $http
        .get(`${gestionDepotDemandesUrl()}/contrats`, config)
        .then(function (response) {
          return _.get(response, 'data._embedded.items');
        })
        .catch(function (err) {
          throw err;
        });
    },

    /**
     * Get sous-thematiques
     *
     * @param {object} thematique
     * @returns {Promise}
     */
    getSousThemathiques: function (thematique) {
      const config = {
        params: {
          $top: 100,
        },
      };

      // Restriction sur la thématique
      config.params.$filter = "actif eq true and thematique/href eq '" + thematique.href + "'";

      return $http
        .get(`${gestionDepotDemandesUrl()}/sousThematiques-financement`, config)
        .then(function (response) {
          return _.get(response, 'data._embedded.items');
        })
        .catch(function (err) {
          throw err;
        });
    },
    /**
     * @returns {Promise}
     */
    getPublicSettingsFinancement: function () {
      return $http
        .get(_.get(configuration, 'publicSettingsFinancement.service'))
        .then(function (response) {
          return _.get(response, 'data');
        })
        .catch(function (err) {
          throw err;
        });
    },
    /**
     * Patch sur une aide
     *
     * @param {object} aide
     * @param {Array} patches
     * @returns {Promise} Retourne l'aide modifiée
     */
    patchAide: function (aide, patches) {
      return $http
        .patch(`${gestionDepotDemandesUrl()}/${ressourcePath()}/${aide.reference}`, patches)
        .then(function (response) {
          return response.data;
        });
    },

    /**
     * Patch an aide only with is reference
     *
     * @param {string} referenceAide
     * @param {Array} patches
     * @returns {Promise} the aide modified
     */
    patchAideByReference: function (referenceAide, patches) {
      return $http.patch(getAideHref(referenceAide), patches).then(function (response) {
        return response.data;
      });
    },

    getAttestations: function (from, size) {
      // EU backend used as middleware to Request
      return $http
        .get('/aides/api/tenants/' + _.get(configuration, 'tenant.id') + '/attestations?from=' + from + '&size=' + size)
        .then((results) => {
          results.data?.hits?.forEach((aide) => {
            aide._source.createdOn = aide._source.history.events[0].date;
            aide._source.demandeAttestationDate = aide._source.history.events.find(
              ({ reference }) => reference === 'WAITING_FOR_CERTIFICATE'
            )?.date;
          });
          return results.data;
        });
    },
    getPublicSettingsGestionDepotDemandes: function () {
      return $http
        .get(_.get(configuration, 'publicSettingsGestionDepotDemandes.service'))
        .then(function (response) {
          return _.get(response, 'data');
        })
        .catch(function (err) {
          throw err;
        });
    },
    /**
     * Retourne si une aide est pluriannuelle multi-financeur avec mode de préinstruction partagé
     *
     * @param {object} aide aide
     * @returns {boolean}
     */
    isCPO: function (aide) {
      return _.get(aide, '_metadata.isCpo');
    },

    /**
     * Returns if an aide is multi-year and from an automatic rollover
     *
     * @param {object} aide aide
     * @returns {boolean}
     */
    isRenewed: (aide) => _.get(aide, '_metadata.isDemandeRenewed', false),

    getPlanFinancementByAnnee: function (planFinancements, annee) {
      const targetPlanFinancement = _.find(planFinancements, ['periode.exercice', annee]);

      return targetPlanFinancement;
    },

    /**
     * Get current planFinancement of the demande-financement :
     *   - if pluriannuelle multi financeur partagé => plan financement of the current exercice
     *   - otherwise the first planFinancement
     *
     * @param {object} aide aide
     * @returns {object}
     */
    getCurrentPlanFinancement: function (aide = {}) {
      const plansFinancement = aide.planFinancement || _.get(aide, 'expand.planFinancement');
      if (!Array.isArray(plansFinancement) || plansFinancement.length === 0) {
        throw new Error(`getCurrentPlanFinancement - the aide has no 'planFinancement'`);
      }

      const isDemandeRenewed = aide._metadata && aide._metadata.isDemandeRenewed;
      // If the demande-financement has been renewed, we select the planFinancement
      // matching with the current exercice
      const currentYear = isDemandeRenewed
        ? aide.exerciceBudgetaire
        : _.get(aide, 'history.begin.metadata.stepMetadata.exercice');

      let currentPlanFinancement;
      if (currentYear && this.isCPO(aide)) {
        currentPlanFinancement = this.getPlanFinancementByAnnee(aide.planFinancement, currentYear);
      }
      if (!currentPlanFinancement) {
        currentPlanFinancement = aide.planFinancement[0];
      }

      return currentPlanFinancement;
    },

    /**
     * Retrieve the first year of the plan de financement
     *
     * @param {object} planFinancement the plan de financement
     * @returns {number}
     */
    getFirstYearOfPf: function (planFinancement) {
      if (!Array.isArray(planFinancement) || planFinancement.length === 0)
        throw new Error('planFinancement must be an array!');
      // TODO: refactor this because the following default value for periode.exercice does not make sense
      const exercicesSorted = planFinancement.map((pf) => _.get(pf, 'periode.exercice', 0)).sort((a, b) => a - b);
      const exerciceMin = exercicesSorted[0];
      return exerciceMin;
    },

    /**
     * Retrieve the first plan de financement
     *
     * @param {object} aide the current demande
     * @returns {Promise}
     */
    getFirstPlanFinancement: function (aide) {
      const planFinancement = _.get(aide, 'planFinancement');
      if (!planFinancement || !Array.isArray(planFinancement))
        throw new Error(`'planFinancement' must be a valid array`);
      const firstYear = this.getFirstYearOfPf(planFinancement);
      let firstPlanFinancement = _.find(planFinancement, ['periode.exercice', firstYear]);
      // Return planFinancement[0] for retrocompatibilty
      if (!firstPlanFinancement && planFinancement.length > 0) firstPlanFinancement = planFinancement[0];
      return firstPlanFinancement;
    },

    checkExternalCritere: function (critere, teleservice) {
      const tenant = _.get(configuration, 'tenant.id');
      const uri = `/aides/api/tenants/${tenant}/teleservices/${teleservice}/criteres/${critere.reference}/check`;
      const config = {
        params: {
          reponseSaisie: critere.reponseSaisie,
        },

        headers: {
          'X-No-Interceptor': true,
        },
      };

      return $http.get(uri, config).then((response) => {
        return { critere: critere, result: response.data };
      });
    },

    /**
     * Copy document from the aide on each piece of the tiers
     *
     * @param {*} tiers tiers containing the piece where the aide document should be copied
     * @param {*} aide aide containing the documents that should be copied on the tiers
     * @returns {Promise} a promise containing the result of the documents copy requests
     */
    copyAideDocumentsOnTiers: function (tiers, aide) {
      const cmisBaseUrl = cmisService.getBaseUrl();
      const documentsCopyPromises = [];

      tiers.pieces
        .filter((tiersPiece) => {
          tiersPiece.documents = tiersPiece.documents || [];

          //! only copy the documents on the tiers piece if it has no document
          return tiersPiece.documents.length === 0;
        })
        .forEach((tiersPiece) => {
          const aidePiece = aide.pieces.find(({ reference }) => reference === tiersPiece.reference);

          // Copy all documents from the aide for this piece on the tiers
          // If we reference a document from 'porte-document' is href contains 'tiers'
          const aidePieceDocuments = aidePiece?.documents;
          aidePieceDocuments?.forEach((documentAide) => {
            const documentIsFromAideEntity =
              documentAide.expand?.properties?.['entity:uri']?.value?.includes('demandes-financement');
            const documentIsFromTiersOrigin = documentAide.origin?.includes('tiers'); //(e.g. selected in the porte document of the tiers)

            if (documentIsFromAideEntity && !documentIsFromTiersOrigin) {
              const newTiersDocument = angular.copy(documentAide);
              const documentCopyPromise = cmisService
                .copyDocument(`${cmisBaseUrl}/tiers/${tiers.reference}`, newTiersDocument, 'tiers', tiers)
                .then((document) => {
                  const documentWithoutExpand = _.omit(document, ['expand']);
                  tiersPiece.documents.push(documentWithoutExpand);
                });
              documentsCopyPromises.push(documentCopyPromise);
            }
          });
        });

      return $q.all(documentsCopyPromises);
    },

    /**
     * Create missing missing pieces on tiers
     *
     * Copy aide pieces that are in teleservice models and in the tiers famille
     * but are missing on the tiers
     * (i.e. pieces in the porte document of the tiers which are set on the teleservice)
     *
     * @param {*} aide aide containing the pieces that should be copied on the tiers
     * @param {*} tiers tiers where the pieces are copied
     */
    createMissingPiecesOnTiers: function (aide, tiers) {
      tiers.pieces = tiers.pieces ?? [];

      const teleservicePiecesModels = aide.teleservice?.expand?.workflow?.pagePieces?.modelesPieces;

      aide.pieces?.forEach((piece) => {
        const pieceOnTeleservice = teleservicePiecesModels?.find(({ reference }) => reference === piece.reference);

        if (pieceOnTeleservice) {
          const pieceOnFamille = tiers.famille.expand?.pieces?.find(({ reference }) => reference === piece.reference);

          const pieceOnTiers = tiers.pieces.find(({ reference }) => reference === pieceOnTeleservice.reference);

          if (pieceOnFamille && !pieceOnTiers) {
            const clonedPiece = angular.copy(piece);
            const newPiece = new Piece(clonedPiece);
            const cleanedPiece = newPiece.getCleanEntity();
            cleanedPiece.documents = [];
            delete cleanedPiece?.conditionAffichage;
            delete cleanedPiece?.conditionObligatoire;
            delete cleanedPiece?.obligatoireSurRecevabilite;
            tiers.pieces.push(cleanedPiece);
          }
        }
      });
    },

    /**
     * Create the aide beneficiaires
     *
     * Create the tiers in the beneficiaires property and update that property
     * with the newly created tiers
     *
     * If there is no beneficiaire in that property, fill it with the demandeur
     * since this means that the demandeur should be the beneficiaire
     *
     * @param {object} aide aide containing the beneficiaires that should be created
     * @param {object[]} aide.beneficiaires aide beneficiaires
     * @param {object} demandeur demandeur tiers in case the demandeur is the beneficiaire
     * @returns {Promise<object[]>} the tiers beneficiaires updated property of the aide
     */
    createBeneficiaires: function (aide, demandeur) {
      if (this.isModificationAttestation(aide)) {
        if (!aide.beneficiaires?.length && aide.demandeurEtBeneficiaire) {
          aide.beneficiaires = [
            {
              rel: 'tiers',
              title: tiersService.getTitleTiers(demandeur),
              href: demandeur.id,
              new: false,
            },
          ];
        }
        // in case of second transmission (by signataire), we just keep the current beneficiaires because it would have been created on initial transmission
        return $q.resolve(aide.beneficiaires);
      }

      const beneficiairesCreationPromises = _.map(aide.beneficiaires, (beneficiaire) => {
        const tiersBeneficiaire = beneficiaire.expand;
        tiersBeneficiaire.status = 'TRANSMITTED';
        return tiersService.saveTiersAndThematiques(tiersBeneficiaire);
      });

      return $q.all(beneficiairesCreationPromises).then((beneficiairesCreated) => {
        const demandeurIsBeneficiaire = beneficiairesCreated.length === 0;

        if (demandeurIsBeneficiaire) {
          aide.beneficiaires = [
            {
              rel: 'tiers',
              title: tiersService.getTitleTiers(demandeur),
              href: demandeur.id,
            },
          ];
        } else {
          aide.beneficiaires = _.map(beneficiairesCreated, function (beneficiaireCreated) {
            return {
              rel: 'tiers',
              title: tiersService.getTitleTiers(beneficiaireCreated),
              href: beneficiaireCreated.id,
            };
          });
        }

        return aide.beneficiaires;
      });
    },

    /**
     * Create the aide demandeur
     *
     * Creates the demandeur and update the demandeur property on the aide with
     * the newly created tiers
     *
     * @param {*} demandeur demandeur tiers
     * @param {*} aide aide where demandeur is the demandeur
     * @returns {Promise} promise of the created demandeur tiers
     */
    createDemandeur: function (demandeur, aide) {
      demandeur.status = 'TRANSMITTED';
      demandeur.user = aide.user;
      return tiersService.saveTiersAndThematiques(demandeur);
    },

    /**
     * Returns metadata about demande, if still open?
     *
     * @param {string} demFinId demande financement id
     * @returns {Promise<boolean>} opened metadata object
     */
    isOpen(demFinId) {
      if (!demFinId) {
        throw new Error("'demFinId' is required");
      }
      const url = `${demFinId}/is-open`;
      return $http.get(url).then((res) => res.data);
    },

    /**
     * Update the groupes de gestion of the demandeur
     *
     * Add the groupe de gestion of the bourse as a groupe de gestion secondaire on the tiers if it
     * exists and is not already present on the tiers (the added groupe de gestion is the the groupe
     * de gestion principal of the composante or the etablissement)
     *
     * This provide more flexibility to set the permissions on the tiers by setting permissions
     * for multiple groupes de gestion
     * If an acl grants a role on a tiers for one of its groupe de gestion, the user will have
     * this role granted on the tiers even if other acls deny this role on another groupe de gestion
     *
     * This function only adds new groupes de gestion on the tiers. By doing this it may grant roles
     * to users that were not granted to some users without denying roles to users that previously
     * had them (no need for duplicate groupes de gestion)
     *
     * @param {object} aide aide
     * @returns {Promise}
     */
    updateDemandeurGroupesGestion: function (aide) {
      if (groupesGestionService.areGroupesGestionActive() && aide.bourse) {
        const getBourse = bourseService.getBourseById(aide.bourse.href);
        const getDemandeur = tiersService.getTiersByReference(aide.demandeur.href.split('/').pop());

        return $q.all([getBourse, getDemandeur]).then(([bourse, demandeur]) => {
          if (!bourse.groupeGestion) {
            return aide;
          }

          const patches = [];

          if (!demandeur.groupesGestion) {
            patches.push({
              op: 'add',
              path: '/groupesGestion',
              value: [bourse.groupeGestion],
            });
          } else if (!_.some(demandeur.groupesGestion, { href: bourse.groupeGestion.href })) {
            patches.push({
              op: 'add',
              path: '/groupesGestion/-',
              value: bourse.groupeGestion,
            });
          }

          if (patches.length > 0) {
            return tiersService.patchTiers(demandeur.reference, patches).then(() => aide);
          } else {
            return aide;
          }
        });
      } else {
        return $q.resolve(aide);
      }
    },
    /**
     * Generate demandes-report table iframe src
     *
     * @param {string} referenceDemande
     * @returns {Promise}
     */
    generateDemandeReportTableIframeSrc: (referenceDemande) => {
      const templateDemandesReportTableIframeSrc = `${configuration.aides.v2.url}/<%= demandeId %>/demandesReport/table?jwtKey=<%= jwtKey %>`;
      const compiledDemandesReportTableIframeSrc = _.template(templateDemandesReportTableIframeSrc);
      const key = jwtSessionService.getJwtKey();

      const demandesReportTableIframeSrc = compiledDemandesReportTableIframeSrc({
        demandeId: referenceDemande,
        jwtKey: key,
      });

      return $sce.trustAsResourceUrl(demandesReportTableIframeSrc);
    },

    /**
     * Generate demandes-report saisie iframe src
     *
     * @param {string} referenceDemande
     * @returns {Promise}
     */
    generateDemandeReportSaisieIframeSrc: (referenceDemande) => {
      const templateDemandesReportSaisieIframeSrc = `${configuration.aides.v2.url}/<%= demandeId %>/demandesReport/saisie?jwtKey=<%= jwtKey %>`;
      const compiledDemandesReportSaisieIframeSrc = _.template(templateDemandesReportSaisieIframeSrc);
      const key = jwtSessionService.getJwtKey();

      const demandeReportSaisieIframeSrc = compiledDemandesReportSaisieIframeSrc({
        demandeId: referenceDemande,
        jwtKey: key,
      });

      return $sce.trustAsResourceUrl(demandeReportSaisieIframeSrc);
    },

    /**
     * Generate demande-apprennat recapitulatif iframe src
     *
     * @param {string} demandeFinancementReference
     * @param {object} additionalParams
     * @param {string} viewName
     * @returns {string}
     */
    generateDemandeApprenantRecapitulatifIframeSrc: (
      demandeFinancementReference,
      additionalParams = {},
      viewName = 'demande-apprenant'
    ) => {
      const queryParams = $httpParamSerializer({
        jwtKey: jwtSessionService.getJwtKey(),
        tiersKey: 'current-tiers-ref',
        demandeFinancementReference,
        ...additionalParams,
      });

      return `${configuration.ux}${configuration.tenant.id}/${viewName}-recapitulatif-view?${queryParams}`;
    },

    postUpdateAide: postUpdateAide,

    gererExpandDocumentsAide,

    /**
     * Find aide.statut for financeur principal
     *
     * @param {object} aide
     * @returns {string}
     */
    statutForFinanceurPrincipal(aide) {
      const currentPlanFinancement = this.getCurrentPlanFinancement(aide);
      const lignes = JSONPath('$.recette...lignes.*', currentPlanFinancement);

      const groupesGestionFinanceurPrincipal = _.get(aide, 'financeurPrincipal.groupesGestion', []);

      const ligneFinanceurPrincipal = _.find(lignes, (ligne) => {
        const groupesGestionLigne = _.get(ligne, 'financement.financeur.groupesGestion', []);

        // Intersection of both groupGestions array, performed on object's hrefs
        var commonGroups = _.intersectionWith(
          groupesGestionFinanceurPrincipal,
          groupesGestionLigne,
          (a, b) => a.href === b.href
        );

        return commonGroups.length > 0;
      });

      const status = _.get(ligneFinanceurPrincipal, 'financement.statut');

      return status || aide.status;
    },

    isLigneFictiveHaveActiveDecisions,
    /**
     * Check if the user has the right to access the current demande for depot
     * with the tiers he has selected
     *
     * @param {object} demande demande (needs to have demandeur expanded)
     * @param {object} userSelectedTiers tiers selected by the user
     * @returns {boolean} true if the user can acces depot for the demande
     */
    canAccessDepotForDemande(demande, userSelectedTiers) {
      const isInCreationProcess = demande.status === 'REQUESTED';
      // Get the status from the expanded demandeur
      // because the injected "demandeur" might not be the same and be the selected tiers
      const expandedDemandeur = _.get(demande, 'demandeur.expand') || {};
      const demandeurExists = expandedDemandeur.id;
      const demandeurKnown = _.includes(tiersService.TIERS_KNOWN_STATUSES, expandedDemandeur.status);
      const demandeurIsSelectedTiers = userSelectedTiers && expandedDemandeur.id === userSelectedTiers.id;

      const canAccessDepotForThisDemande =
        !demandeurExists || (demandeurKnown && demandeurIsSelectedTiers) || !userSelectedTiers;

      return !isInCreationProcess || canAccessDepotForThisDemande;
    },

    isRequested,

    /**
     * Returns if aide is owned by current user and is requested
     *
     * @param {object} aide
     * @returns {boolean}
     */
    canBeShared: (aide) => isOwnedByCurrentUser(aide) && isRequested(aide),

    /**
     * Check if aide is shared with current user
     * Currently, only the creator can see the demande before it is transmitted.
     * If aide has no reference, it cannot be shared yet.
     * If demande is requested and user is not creator, it means that the demande is shared with him.
     * If the demande is not requested and the demande is restricted to user,
     * it means that he is linked to demandeur or beneficiaire BUT he cannot see it on "Mes demandes" => the demande is shared with him
     * otherwise, if the user is not the demandeur or beneficiaire, it means that the demande is shared with him.
     *
     * @param {object} aide
     * @param {object} tiers
     * @returns {boolean}
     */
    isAccessedThroughSharing: (aide, tiers) => {
      if (!aide?.reference) {
        return false;
      }

      if (isRequested(aide)) {
        return !isOwnedByCurrentUser(aide);
      }

      if (isTiersDemandeur(aide, tiers) || isTiersBeneficiaire(aide, tiers)) {
        return demandesFinancementAPI
          .isDemandeRestrictedToUser(aide.id)
          .then((isDemandeRestrictedToUser) => !!isDemandeRestrictedToUser);
      }

      return true;
    },

    isTiersDemandeur,

    isTiersBeneficiaire,

    isOwnedByCurrentUser,

    /**
     * Computes if aide has been transmitted
     *
     * @param {object} aide the aide we want to know if it has been transmitted or not
     * @returns {boolean} true if aide has transmitted status or higher
     */
    hasBeenTransmitted: (aide) => {
      const beforeTransmissionStatuses = [
        AIDE_STATUS.REQUESTED,
        AIDE_STATUS.WAITING_FOR_CERTIFICATE,
        AIDE_STATUS.REGISTERED,
      ];
      const aideStatus = aide?.status;

      return !beforeTransmissionStatuses.includes(aideStatus);
    },

    /**
     * Update avis situation data on aide (external data)
     *
     * @param {string} demandeReference reference of the demande
     * @param {object} options
     * @param {string} options.numeroAllocataire numero allocataire
     * @param {string} options.codePostal code postal
     * @returns {Promise<object>} update result
     */
    updateAvisSituation(demandeReference, { numeroAllocataire, codePostal }) {
      return $http
        .post(`${gestionDepotDemandesUrl()}/${ressourcePath()}/${demandeReference}/update-composition-familiale`, {
          numeroAllocataire,
          codePostal,
        })
        .then(({ data }) => data);
    },

    /**
     * Check if user's family match with TS Configuration
     *
     * @param {string} beneficiaireFamille id of beneficiaire famille
     * @param {object} teleserviceConfiguration teleservice configuration
     * @returns {boolean} user's family is allowed
     */
    isBeneficiaireFamilyAuthorized(beneficiaireFamille, teleserviceConfiguration) {
      const configBeneficiaireFamilies = teleserviceConfiguration.workflow?.pageInformationsBeneficiaire?.familles?.map(
        ({ famille }) => famille?.href
      );

      const hasNoFamilleBeneficiaireConfigured = !configBeneficiaireFamilies?.length;
      const isFamilleBeneficiaireInConfiguredFamilles = configBeneficiaireFamilies?.includes(beneficiaireFamille);
      return hasNoFamilleBeneficiaireConfigured || isFamilleBeneficiaireInConfiguredFamilles;
    },

    /**
     * Check if given aide modification is from attestation
     *
     * @param {object} aide - aide to check
     * @returns {boolean} if given aide modification is from attestation
     */
    isModificationAttestation(aide) {
      // We're modifying a "demande en attestation" if it's current status is REQUESTED and previous status is WAITING_FOR_CERTIFICATE
      const status = aide?.history?.events ?? [];
      return (
        status.length > 1 &&
        status[status.length - 1].reference === statusContributions.REQUESTED &&
        status[status.length - 2].reference === statusContributions.WAITING_FOR_CERTIFICATE
      );
    },
  };
}
